import { useEffect, useState } from "react";
import { useLoginMutation, useRegisterMutation } from "@/redux/features/authApiSlice";
import { setAuth } from "@/redux/features/authSlice";
import { useAppDispatch } from "@/redux/hooks";

export default function useRegister(onClose: () => void) {
  const dispatch = useAppDispatch();
  const [register, { isLoading }] = useRegisterMutation();
  const [login] = useLoginMutation();
  const [lenghtPass, setLenghtPass] = useState<boolean | undefined>(undefined);
  const [capitalPass, setCapitalPass] = useState<boolean | undefined>(undefined);
  const [lowerPass, setLowerPass] = useState<boolean | undefined>(undefined);
  const [numberPass, setNumberPassPass] = useState<boolean | undefined>(undefined);
  const [notMatch, setNotMatch] = useState<boolean | undefined>(undefined);
  const [isError, setIsError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [messageError, setMessageError] = useState("Ha habido un problema con el servidor");
  const [formData, setFormData] = useState({
    email: "",
    user_name: "",
    password: "",
    re_password: "",
  });

  useEffect(() => {
    if (formData.password) {
      setLowerPass(/[a-z]/.test(formData.password));
      setCapitalPass(/[A-Z]/.test(formData.password));
      setNumberPassPass(/\d/.test(formData.password));
      setLenghtPass(formData.password.length >= 8);
    } else {
      setLowerPass(undefined);
      setCapitalPass(undefined);
      setNumberPassPass(undefined);
      setLenghtPass(undefined);
    }
  }, [formData.password]);

  useEffect(() => {
    if (formData.password && formData.re_password) {
      setNotMatch(formData.password === formData.re_password);
    } else {
      setNotMatch(undefined);
    }
  }, [formData.re_password, formData.password]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      user_name: formData.email ? formData.email.split("@")[0] : prevData.user_name,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsError(false);

    if (!notMatch) {
      setIsError(true);
      setIsPasswordError(true);
      setIsEmailError(false);
      setMessageError("Passwords don't match. Please try again.");
      return;
    }

    if (!lowerPass || !capitalPass || !lenghtPass || !numberPass) {
      setIsPasswordError(true);
      setIsEmailError(false);
      setIsError(true);
      setMessageError(
        "Your password must include at least eight characters, one uppercase letter, one lowercase letter, and one number."
      );
      return;
    }

    register(formData)
      .unwrap()
      .then(() => {
        login({ email: formData.email, password: formData.password })
          .unwrap()
          .then((response) => {
            dispatch(setAuth(response));
            onClose();
            window.location.href = '/account';
          })
          .catch(() => {
            setMessageError("Ha habido un error al ingresar.");
            setIsError(true);
          });
      })
      .catch((error) => {
        if (error.data) {
          Object.keys(error.data).forEach((key) => {
            const errorMessage = Array.isArray(error.data[key])
              ? error.data[key].join(" ")
              : error.data[key];
            if (errorMessage === "user account with this email already exists.") {
              setMessageError("This email is already in use. Please try logging in or using a different email.");
              setIsEmailError(true);
              setIsPasswordError(false);
            } else {
              setMessageError(errorMessage);
            }
          });
        }
        setIsError(true);
      });
  };

  return {
    formData,
    isLoading,
    isError,
    isPasswordError,
    isEmailError,
    messageError,
    handleChange,
    handleSubmit,
    lenghtPass,
    capitalPass,
    lowerPass,
    numberPass,
    notMatch,
  };
}

