import RequirePass from "@/components/atoms/labels/RequirePass";

export default function ValidateStrenght({
  lenghtPass,
  capitalPass,
  lowerPass,
  numberPass,
}: {
  lenghtPass?: boolean;
  capitalPass?: boolean;
  lowerPass?: boolean;
  numberPass?: boolean;
}) {
  return (
    <div className="grid gap-3 grid-cols-2 w-full">
      <div className="justify-self-start">
        <RequirePass text="8 characters" active={lenghtPass} />
      </div>
      <div className="">
        <RequirePass text="One uppercase letter" active={capitalPass} />
      </div>
      <div className="justify-self-start">
        <RequirePass text="One lowercase letter" active={lowerPass} />
      </div>
      <div className="">
        <RequirePass text="Number" active={numberPass} />
      </div>
    </div>
  );
}

