import InputField from "@/components/atoms/inputs/InputField";
import { EInputsTypes } from "@/types/emuns";
import { useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

export default function InputHideShow({
  value,
  onChange = () => {},
  name,
  id,
  className = "",
  placeholder = "",
}: {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id: string;
  className: string;
  placeholder?: string;
}) {
  const [icon, setIcon] = useState({ icon: FaRegEyeSlash });
  const [type, setType] = useState(EInputsTypes.password);

  const handleToggle = () => {
    if (type === EInputsTypes.password) {
      setType(EInputsTypes.text);
      setIcon({ icon: FaRegEye });
    } else {
      setType(EInputsTypes.password);
      setIcon({ icon: FaRegEyeSlash });
    }
  };

  return (
    <>
      <InputField
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        name={name}
        id={id}
        className={`${className}`}
      />
      <div onClick={handleToggle}>
        <icon.icon className="w-4 h-4 top-1/2 -translate-y-1/2 right-2 absolute cursor-pointer" />
      </div>
    </>
  );
}
