export default function InputLabel({
  text,
  className = "",
}: {
  text: string;
  className?: string;
}) {
  return (
    <h2 lang="en" className={`${className ? className : "w-40 text-base text-start"}`}>
      {text}
    </h2>
  );
}
