import { IconType } from "react-icons";

export default function ErrorLabel({
  label,
  icon,
  className = "",
}: {
  label: { text: string; className?: string };
  icon?: { icon: IconType; className: string; fillColor: string };
  className?: string;
}) {
  return (
    <div className={`${className}`}>
      {icon && <icon.icon className={icon.className} fill={icon.fillColor} />}
      <div className={label.className}>{label.text}</div>
    </div>
  );
}
