import React from 'react';
import useRegister from '@/hooks/useRegister';
import FormSignIn from '@/components/molecules/forms/FormSignIn';

export default function Signin({ onClose }: { onClose: () => void }) {
  const {
    formData,
    handleChange,
    handleSubmit,
    isLoading,
    isError,
    isPasswordError,
    isEmailError,
    messageError,
    lenghtPass,
    capitalPass,
    lowerPass,
    numberPass,
    notMatch,
  } = useRegister(onClose);

  return (
    <div className="flex flex-col items-center bg-customGray rounded-lg border-black border-2">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 border border-gray-400 text-gray-500 h-6 w-6 flex items-center justify-center hover:bg-gray-200"
        >
          &times;
        </button>
      <h2 className="text-red-500 text-2xl mb-4 mt-4">WELCOME BACK</h2>
      <hr className="border-t-2 border-gray-500 w-11/12 mb-4" />
      <FormSignIn
        onSubmit={handleSubmit}
        onChange={handleChange}
        loading={isLoading}
        values={formData}
        isError={isError}
        isPasswordError={isPasswordError}
        isEmailError={isEmailError}
        messageError={messageError}
        lenghtPass={lenghtPass}
        capitalPass={capitalPass}
        lowerPass={lowerPass}
        numberPass={numberPass}
        matchPass={notMatch}
      />
    </div>
  );
}
