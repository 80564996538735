import { ImSpinner5 } from "react-icons/im";

export default function SignLogIn({
  text,
  loading,
  onClick,
  disabled = false,
  className = "",
}: {
  text: string | React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="submit"
      className={`${disabled && "cursor-not-allowed"} ${
        className ? className : "hover:bg-blue-800/80 bg-blue-800"
      }`}
    >
      {loading ? (
        <div className="flex items-center justify-center">
        </div>
      ) : (
        text
      )}
    </button>
  );
}
