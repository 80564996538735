"use client";
import React from "react";
import Login from "@/components/organisms/Login";
import { useModal } from "@/components/layouts/modals/ModalContext";

export default function LoginModal() {
  const { isLoginModalOpen, closeLoginModal } = useModal();

  return (
    <>
      {isLoginModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeLoginModal}
        >
          <div
            className="bg-customGray w-full max-w-md md:max-w-lg rounded-xl shadow-lg relative p-6"
            onClick={(e) => e.stopPropagation()}
          >
            <Login onClose={closeLoginModal} />
          </div>
        </div>
      )}
    </>
  );
}



