import AnchorTitle from "../atoms/anchors/AnchorTitle";
import AnchorTextAboutUs from "../atoms/anchors/AnchorTextAboutUs";
import Image from "next/image";
import AuthLogo from "@/assets/images/AuthLogo.png";
import AboutUs from "@/assets/images/AboutUs.jpg";
import Separator from "@/assets/images/Separador.png"

export default function AboutUsLayout() {
    return (
      <div className="">
        <div
          id="about-us-section"
          className="text-center mb-4 pt-20"
        >
          <AnchorTitle className="text-red-500 text-4xl font-bold" text="ABOUT US" />
        </div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <AnchorTextAboutUs
              className="text-black text-xl"
              paragraph1="Impact DW is a manufacturer of high-quality, custom-made doors. We cater to businesses of all sizes, from small contractors to large construction firms, and offer a wide variety of door and windows styles, materials, and configurations to meet your exact needs. Our commitment to excellence and customer satisfaction sets us apart in the industry."
              paragraph2="At Impact DW, we believe in making the process of obtaining custom doors and windows as seamless and straightforward as possible. Our easy-to-follow steps ensure that you receive the finest products with minimal hassle. From initial registration to the final collection of your doors, we provide continuous support and updates to keep you informed every step of the way."
            />
          </div>
          <div className="w-full lg:w-1/2 flex items-center justify-center">
            <Image
              src={AboutUs}
              alt="Auth Logo"
              width={530}
              height={100}
              className="object-contain"
            />
          </div>
        </div>
        <div className="flex justify-center">
          <Image
            src={Separator}
            alt="Separator"
            layout="responsive"
            className="object-contain"
          />
        </div>
      </div>
    );
  }
  


