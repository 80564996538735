import React from "react";

interface AnchorTextProps {
  className?: string;
  grayText: string;
  blueText: string;
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const AnchorText: React.FC<AnchorTextProps> = ({
  className,
  grayText,
  blueText,
  href,
  onClick,
}) => {
  return (
    <div className={className}>
      <span>{grayText}</span>
      <a href={href} onClick={onClick} className="text-blue-500">
        {blueText}
      </a>
    </div>
  );
};

export default AnchorText;


  