import cn from "classnames";

export default function RequirePass({
  text,
  active,
}: {
  text: string;
  active: boolean | undefined;
}) {
  const className = cn("border-solid border-2 rounded-full h-3 w-3 mr-1", {
    "border-green-500 bg-green-300": active === true,
    "border-red-500 bg-red-300": active === false,
    "border-black": active === undefined,
  });
  return (
    <div className="flex items-center">
      <div className={className}></div>
      <div className="text-sm">{text}</div>
    </div>
  );
}
