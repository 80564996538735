import { useLoginMutation } from "@/redux/features/authApiSlice";
import { setAuth } from "@/redux/features/authSlice";
import { useAppDispatch } from "@/redux/hooks";
import { useState } from "react";

interface UseLoginProps {
  onSuccess?: () => void;
}

export default function useLogin({ onSuccess }: UseLoginProps) {
  const dispatch = useAppDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [errorState, setErrorState] = useState({
    isError: false,
    errorMessage: "",
  });
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorState({ isError: false, errorMessage: "" });

    try {
      const response = await login(loginData).unwrap();
      dispatch(setAuth(response));

      if (response.show_approval_popup && !localStorage.getItem("approval_popup_shown")) {
        localStorage.setItem("approval_popup_shown", "true");
      }

      if (response.is_admin) {
        window.location.href = "/admin";
      } else if (response.account_status === "Active") {
        window.location.href = "/drafts";
      } else {
        window.location.href = "/account";
      }

      onSuccess?.();
    } catch (error: any) {
      const errorMessages = error?.data
        ? Object.values(error.data).flat().join(" ")
        : "An unexpected error occurred";

      setErrorState({
        isError: true,
        errorMessage: errorMessages,
      });
    }
  };

  return {
    loginData,
    isLoading,
    isError: errorState.isError,
    errorMessage: errorState.errorMessage,
    handleChange,
    handleSubmit,
  };
}
