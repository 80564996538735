import React, { useState } from 'react';
import InputField from "@/components/atoms/inputs/InputField";
import InputLabel from "@/components/atoms/labels/InputLabel";
import { IModel } from "@/types";
import { EInputsTypes } from "@/types/emuns";
import FormLayout from "../../layouts/FormLayout";
import InputHideShow from "../InputHideShow";
import ErrorLabel from "@/components/atoms/labels/ErrorLabel";
import { BiSolidMessageError } from "react-icons/bi";
import ValidateStrenght from "../auth/ValidateStrenght";
import ReCAPTCHA from "react-google-recaptcha";
import cn from "classnames";

export default function FormSignIn({
  onSubmit,
  onChange = () => {},
  loading,
  values,
  isError = false,
  isPasswordError = false,
  isEmailError = false,
  matchPass,
  lenghtPass,
  capitalPass,
  lowerPass,
  numberPass,
  messageError = "",
}: {
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  values: IModel.IUserRegister;
  matchPass?: boolean;
  lenghtPass?: boolean;
  capitalPass?: boolean;
  lowerPass?: boolean;
  numberPass?: boolean;
  isError?: boolean;
  isPasswordError?: boolean;
  isEmailError?: boolean;
  messageError?: string;
}) {
  const [verify, setVerify] = useState(false);
  const className = cn(
    "my-2 w-full pr-7 border-solid border-[1px] border-transparent focus:border-transparent focus:border-b-black/50 focus:ring-0 outline-none",
    {
      "border-b-green-500": matchPass === true,
      "border-b-red-500": matchPass === false,
      "border-b-black/50": matchPass === undefined,
    }
  );

  const buttonConf = {
    text: "Sign in",
    className:
      "bg-[#ff0000]/80 hover:bg-[#ff0000]/50 transition-colors bg-opacity-70 px-4 py-1 rounded-lg my-5 text-white text-sm",
    loading: loading,
  };

  function handleRecaptcha(value: any) {
    if (value) {
      setVerify(true);
    } else {
      setVerify(false);
    }
  }

  return (
    <FormLayout
      disButton={!verify}
      onSubmit={onSubmit}
      className="mt-3 pt-3 flex flex-col items-center justify-center bg-customGray"
      buttonConf={buttonConf}
    >
      <div className="relative flex flex-col px-8 mb-5">
        <div className="flex items-center justify-start space-x-2">
          <InputLabel text="Email:*" className="whitespace-nowrap text-start" />
          <div className="flex-1">
            <InputField
              onChange={onChange}
              type={EInputsTypes.email}
              value={values.email}
              name="email"
              id="email"
              className="w-full border-solid border-[1px] border-b-black/50 border-transparent focus:border-transparent focus:border-b-black/50 focus:ring-0 outline-none"
            />
          </div>
        </div>
        {isEmailError && messageError && (
              <ErrorLabel
                label={{
                  text: messageError,
                  className: "text-red-600 w-96 leading-tight",
                }}
                icon={{
                  icon: BiSolidMessageError,
                  className: "w-9 h-9 p-1",
                  fillColor: "red",
                }}
                className="flex items-start"
              />
            )}
        <div className="flex items-center relative justify-start space-x-2">
          <InputLabel text="Password:*" className="my-2 text-start" />
          <div className="flex-1">
            <InputHideShow
              onChange={onChange}
              value={values.password}
              name="password"
              id="password"
              className={className}
            />
          </div>
        </div>
        <div className="flex items-center relative justify-start space-x-2">
          <InputLabel
            text="Confirm password:*"
            className="my-2 whitespace-nowrap text-start"
          />
          <div className="flex-1">
            <InputHideShow
              onChange={onChange}
              value={values.re_password}
              name="re_password"
              id="re_password"
              className={className}
            />
          </div>
        </div>
        {isPasswordError && messageError && (
              <ErrorLabel
                label={{
                  text: messageError,
                  className: "text-red-600 w-96 leading-tight",
                }}
                icon={{
                  icon: BiSolidMessageError,
                  className: "w-9 h-9 p-1",
                  fillColor: "red",
                }}
                className="flex items-start"
              />
            )}
        <div className="flex items-start mt-2">
          <ValidateStrenght
            lowerPass={lowerPass}
            capitalPass={capitalPass}
            lenghtPass={lenghtPass}
            numberPass={numberPass}
          />
        </div>
      </div>
      <ReCAPTCHA
        sitekey={`${process.env.NEXT_PUBLIC_CAPT_PUB}`}
        onChange={handleRecaptcha}
        hl="en"
      />
    </FormLayout>
  );
}








