import AnchorTitle from "../atoms/anchors/AnchorTitle";

export default function LoginLayout({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) {
  return (
    <div className="flex flex-col items-center justify-center relative bg-customGray text-black p-4">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 border border-gray-400 text-gray-500 h-6 w-6 flex items-center justify-center hover:bg-gray-200"
      >
        &times;
      </button>
      <AnchorTitle
        className="text-red-500 text-2xl mb-4"
        text="LOG IN"
      />
      <hr className="border-t-2 border-gray-400 w-full mb-4" />
      {children}
    </div>
  );
}












