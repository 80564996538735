import { motion } from "framer-motion";
import Image from "next/image";
import AuthBackground from "@/assets/images/AuthBackground.jpg";

export default function BackgroundImage() {
  return (
    <div className="relative w-full h-96  overflow-hidden">
      <div
        className="absolute inset-0 z-0 flex items-center justify-center"
        style={{
          backgroundImage: `url(${AuthBackground.src})`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0% 80%)",
        }}
      >
        <div className="relative flex items-center justify-center h-full w-full">
          <motion.h1
            className="text-white text-4xl font-bold text-center fixed"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            YOUR ONE-STOP SHOP FOR CUSTOM DOORS AND WINDOWS MANUFACTURING
          </motion.h1>
        </div>
      </div>
    </div>
  );
}
















