import { useModal } from "../layouts/modals/ModalContext";
import LoginButton from "../atoms/buttons/LoginButton";
import AnchorText from "../atoms/anchors/AnchorText";
import LoginInputField2 from "../atoms/inputs/LoginInputField2";
import ErrorLabel from "@/components/atoms/labels/ErrorLabel";

interface FormLoginProps {
  errorLabel?: boolean;
  errorMessage?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loginData: { email: string; password: string };
}


enum EInputsTypes {
  text = "text",
  email = "email",
  password = "password",
}

export default function FormLogin({
  errorLabel = false,
  errorMessage = "",
  handleChange,
  handleSubmit,
  loginData,
}: FormLoginProps) {
  const { openRegisterModal, closeLoginModal } = useModal();

  const handleClickRegister: React.MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.preventDefault();
    closeLoginModal();
    openRegisterModal();
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <LoginInputField2
        label="Email"
        type={EInputsTypes.email}
        onChange={handleChange}
        onInput={handleChange}
        name="email"
        id="email"
      />
      <LoginInputField2
        label="Password"
        type={EInputsTypes.password}
        onChange={handleChange}
        onInput={handleChange}
        name="password"
        id="password"
      />
      {errorLabel && (
        <ErrorLabel
          label={{
            text: errorMessage,
            className: "text-red-600 w-full leading-tight mt-1 text-sm font-semibold text-center",
          }}
          className="flex items-start"
        />
      )}
      <LoginButton
        className="bg-[#ff0000]/80 hover:bg-[#ff0000]/50 transition-colors bg-opacity-70 px-4 py-2 rounded-lg my-5 text-white text-sm w-full"
        text="Login"
      />
      <div className="flex flex-col items-center">
        <AnchorText
          className="text-blue-500 text-sm"
          grayText=""
          blueText="Forgot your password?"
          href="/password-reset"
        />
        <AnchorText
          className="text-gray-500 text-sm mt-2"
          grayText="Don't have an account? "
          blueText="Sign up here"
          href="#"
          onClick={handleClickRegister}
        />
      </div>
    </form>
  );
}


