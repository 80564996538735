export default function AnchorTextAboutUs({
  paragraph1 = "",
  paragraph2 = "",
  className = "",
}: {
  paragraph1: string;
  paragraph2: string;
  className?: string;
}) {
  return (
    <div className={`relative ${className}`}>
      <a className="inline-block relative pl-20 text-black text-justify">
        <p>{paragraph1}</p>
        <br></br>
        <p>{paragraph2}</p>
      </a>
    </div>
  );
}
