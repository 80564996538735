export default function AnchorTitle({
  text,
  className = "",
}: {
  text: string;
  className?: string;
}) {
  return (
    <div className={`relative ${className}`}>
      <a className="inline-block relative text-center w-full">
        {text}
      </a>
    </div>
  );
}
