import { IInputGenericProps } from "@/types/models";

export default function InputField({
  name,
  id,
  value,
  type,
  onChange,
  placeholder,
  className = "",
}: IInputGenericProps) {
  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    event.target.setCustomValidity('Invalid email address. Please make sure your email includes an "@" symbol.');
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.setCustomValidity('');
  };

  return (
    <input
      required
      type={type}
      onChange={(event) => {
        handleInput(event);
        onChange(event);
      }}
      onInvalid={handleInvalid}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      className={`${className}`}
    />
  );
}
