import { IoArrowDownOutline } from "react-icons/io5";
import AnchorTitle from "../atoms/anchors/AnchorTitle";
import AnchorInfogram from "../atoms/anchors/AnchorInfogram";
import ButtonInfogram from "../atoms/buttons/ButtonInfogram";
import Image from "next/image";
import Icon from "@/assets/images/icono1punto.svg";
import Icon2 from "@/assets/images/icon2.svg";
import Icon3 from "@/assets/images/icono3punto.svg";
import Icon4 from "@/assets/images/icono4punto.svg";
import Icon5 from "@/assets/images/icono5punto.svg";
import { useModal } from '@/components/layouts/modals/ModalContext';

export default function InfogramLayout() {
  const { openLoginModal, openRegisterModal } = useModal();

  return (
    <div className="pt-20 pb-10">
      <div className="text-center mb-2 relative">
        <AnchorTitle className="text-red-500 text-4xl font-bold" text="START ORDER TODAY!" />
        <AnchorTitle className="text-black text-xl pb-2" text="Ready to become a supplier of our top-notch products? Here's how it works:" />
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-80 h-1 bg-red-500"></div>
      </div>
      <div className="relative flex flex-col items-center">
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-red-500"></div>

        {/* First Block */}
        <div className="w-full md:w-2/3 flex items-center my-8 justify-start relative">
          <div className="absolute left-1/2 transform -translate-x-full top-1/2 h-1 w-8 bg-red-500"></div>
          <div className="w-16 h-16 mr-4 bg-black rounded-full flex items-center justify-center">
            <Image src={Icon} alt="Logo" width={40} height={40} />
          </div>
          <div className="max-w-xs text-justify ml-4 pt-5">
            <AnchorTitle className="text-xl font-bold text-center pb-5" text="REGISTER AS A CLIENT" />
            <AnchorInfogram className="text-gray-700 leading-relaxed" text="It's easy! Fill out our registration form and submit the required documents. We'll review your application quickly, and once approved, you'll be ready to place orders. Don’t worry, we’ll guide you through the entire process with email updates along the way." />
            <div className="flex justify-center">
              <ButtonInfogram text="Let's Go" onClick={openRegisterModal} />
            </div>
          </div>
        </div>

        {/* Second Block */}
        <div className="w-full md:w-2/3 flex items-center my-8 justify-end relative">
          <div className="absolute left-1/2 transform translate-x-0 top-1/2 h-1 w-8 bg-red-500"></div>
          <div className="max-w-xs text-justify mr-4">
            <AnchorTitle className="text-xl font-bold text-center pb-5" text="BUILD YOUR DRAFT (QUOTE)" />
            <AnchorInfogram className="text-gray-700 leading-relaxed" text="Once you’re in the system, you can create quotes for yourself or your clients (you can add your markup to their quotes). Customize your doors exactly how you want them - choose the style, materials, configuration, accessories, and more - all within our easy-to-use platform. You can even include multiple door types in a single quote!" />
            <div className="flex justify-center">
              <ButtonInfogram text="Let's Go" onClick={openLoginModal} />
            </div>
          </div>
          <div className="w-16 h-16 ml-4 bg-black rounded-full flex items-center justify-center">
            <Image src={Icon2} alt="Logo" width={40} height={40} />
          </div>
        </div>

        {/* Third Block */}
        <div className="w-full md:w-2/3 flex items-center my-8 justify-start relative">
          <div className="absolute left-1/2 transform -translate-x-full top-1/2 h-1 w-8 bg-red-500"></div>
          <div className="w-16 h-16 mr-4 bg-black rounded-full flex items-center justify-center">
            <Image src={Icon3} alt="Logo" width={40} height={40} />
          </div>
          <div className="max-w-xs text-justify ml-4">
            <AnchorTitle className="text-xl font-bold text-center pb-5" text="PLACE YOUR ORDER" />
            <AnchorInfogram className="text-gray-700 leading-relaxed" text="Once you’re happy with your quote, it gets sent to our production facility and is put into production. Following your initial 50% deposit, we'll send you a confirmation and invoice via email for your records." />
            <div className="flex justify-center">
              <ButtonInfogram text="Let's Go" onClick={openLoginModal} />
            </div>
          </div>
        </div>

        {/* Fourth Block */}
        <div className="w-full md:w-2/3 flex items-center my-8 justify-end relative">
          <div className="absolute left-1/2 transform translate-x-0 top-1/2 h-1 w-8 bg-red-500"></div>
          <div className="max-w-xs text-justify mr-4">
            <AnchorTitle className="text-xl font-bold text-center pb-5" text="TRACK YOUR ORDER" />
            <AnchorInfogram className="text-gray-700 leading-relaxed" text="After confirmation, you can track your order’s progress through manufacturing and shipping. Always know where your doors are in the process!" />
            <div className="flex justify-center">
              <ButtonInfogram text="Let's Go" onClick={openLoginModal} />
            </div>
          </div>
          <div className="w-16 h-16 ml-4 bg-black rounded-full flex items-center justify-center">
            <Image src={Icon4} alt="Logo" width={40} height={40} />
          </div>
        </div>

        {/* Fifth Block */}
        <div className="w-full md:w-2/3 flex items-center my-8 justify-start relative mb-10">
          <div className="absolute left-1/2 transform -translate-x-full top-1/2 h-1 w-8 bg-red-500"></div>
          <div className="w-16 h-16 mr-4 bg-black rounded-full flex items-center justify-center">
            <Image src={Icon5} alt="Logo" width={40} height={40} />
          </div>
          <div className="max-w-xs text-justify ml-4">
            <AnchorTitle className="text-xl font-bold text-center pb-5" text="RECEIVE YOUR ORDER" />
            <AnchorInfogram className="text-gray-700 leading-relaxed" text="We’ll notify you as soon as your high-quality doors are ready for pickup at our warehouse. Once you make your final 50% payment, you can collect your beautiful, custom-made doors!" />
            <div className="flex justify-center">
              <ButtonInfogram text="Let's Go" onClick={openLoginModal} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-[-22px]">
        <IoArrowDownOutline className="w-8 h-8 text-red-500" />
      </div>
    </div>
  );
}

