import React from 'react';
import LoginLayout from "@/components/layouts/LoginLayout";
import FormLogin from "@/components/molecules/FormLogin";
import useLogin from "@/hooks/useLogin";

export default function Login({ onClose }: { onClose: () => void }) {
  const {
    loginData,
    isLoading,
    isError,
    errorMessage,
    handleChange,
    handleSubmit,
  } = useLogin({ onSuccess: onClose });

  return (
    <LoginLayout onClose={onClose}>
      <FormLogin
        errorLabel={isError}
        errorMessage={errorMessage}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        loginData={loginData}
      />
    </LoginLayout>
  );
}

