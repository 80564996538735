import { useState } from "react";
import { EInputsTypes } from "@/types/emuns";
import { IoEyeOff, IoEye } from "react-icons/io5";

export default function LoginInputField2({
  label,
  type,
  onChange,
  onInput,
  name,
  id,
  required = true,
}: {
  label: string;
  name: string;
  id: string;
  type?: EInputsTypes;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center mb-4 relative w-full">
      <label className="w-24 text-right text-black mr-2">{label}:</label>
      <input
        type={
          showPassword && type === EInputsTypes.password
            ? "text"
            : type
            ? type
            : EInputsTypes.text
        }
        onChange={onChange}
        onInput={onInput}
        name={name}
        id={id}
        required={required}
        className="outline-none w-full border-b-2 px-3 border-black bg-transparent text-gray-900 focus:outline-none focus:border-blue-500"
      />
      {type === EInputsTypes.password && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
        >
          {showPassword ? <IoEye /> : <IoEyeOff />}
        </button>
      )}
    </div>
  );
}
