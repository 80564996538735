"use client"
import React from 'react';
import Signin from '@/components/organisms/Signin';
import { useModal } from '@/components/layouts/modals/ModalContext';

export default function RegisterModal() {
  const { isRegisterModalOpen, closeRegisterModal } = useModal();

  return (
    <>
      {isRegisterModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={closeRegisterModal}>
          <div className="bg-white w-full md:w-1/2 lg:w-1/3 rounded-xl shadow-lg relative" onClick={(e) => e.stopPropagation()}>
            <Signin onClose={closeRegisterModal} />
          </div>
        </div>
      )}
    </>
  );
}


