import Link from 'next/link';

interface ButtonInfogramProps {
  text: string;
  href?: string;
  onClick?: () => void;
  className?: string;
}

export default function ButtonInfogram({ text, href, onClick, className = "" }: ButtonInfogramProps) {
  if (href) {
    return (
      <Link href={href} className={`mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ${className}`}>
        {text}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className={`mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ${className}`}>
      {text}
    </button>
  );
}

