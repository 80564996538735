"use client";

import AboutUsLayout from "@/components/organisms/AboutUs";
import Infogram from "@/components/organisms/Infogram";
import LoginModal from "@/components/layouts/modals/LoginModal";
import RegisterModal from "@/components/layouts/modals/RegisterModal";
import BackgroundImage from "@/components/molecules/header/BackgroundIMage";


export default function Home() {
  return (
    <div>
      <BackgroundImage />
      <div id="about-us-section">
        <AboutUsLayout />
      </div>
      <div id="infogram-section">
        <Infogram />
      </div>
      <LoginModal />
      <RegisterModal />
    </div>
  );
}




