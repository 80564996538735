import SignLogIn from "@/components/atoms/buttons/SignLogIn";

export default function FormLayout({
  children,
  onSubmit,
  disButton = false,
  className,
  buttonConf = {
    text: "",
    className: "",
    loading: false,
  },
}: {
  children?: React.ReactNode;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  disButton?: boolean;
  buttonConf?: {
    text: string;
    className: string;
    loading?: boolean;
  };
}) {
  return (
    <form onSubmit={onSubmit} className={`${className}`}>
      {children}
      <SignLogIn
        disabled={disButton}
        text={buttonConf.text}
        className={buttonConf.className}
        loading={buttonConf.loading}
      />
    </form>
  );
}
