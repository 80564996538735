export default function AnchorInfogram({
  text = "",
  className = ""
}: {
  text: string;
  className?: string;
}) {
  return (
    <a className={`relative ${className} text-justify`}>{text} <br/></a>
  );
}
