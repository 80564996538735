"use client";

import { useUserAccountQuery } from "@/redux/features/authApiSlice";
import InfogramLayout from '../layouts/InfogramLayout';
import InfogramLayoutLogged from '../layouts/InfogramLayoutLogged';

export default function Infogram() {
  const { data: userAccount, isLoading, error } = useUserAccountQuery();

  return (
    <>
      {userAccount?.account_status === "Active" ? (
        <InfogramLayoutLogged />
      ) : (
        <InfogramLayout />
      )}
    </>
  );
}
